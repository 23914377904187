import Image from 'next/image'
import logo from '../../images/header-logo.svg'

const HeaderLogo = ({ width = 178, height = 38, ...rest }) => {
    return (
        <Image
            // eslint-disable-next-line i18next/no-literal-string
            alt="Header logo icon"
            height={height}
            width={width}
            src={logo}
            priority
            {...rest}
        />
    )
}

export default HeaderLogo
